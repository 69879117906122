:local(.cnt){
    display: grid;
    grid-template-columns: 1fr 500px;
    min-height: 100vh;
    background:var(--mainColor)
}
:local(.message){
    grid-column: 1/span 1;
    height: auto;
    padding:70px 100px 0 100px;
    .logo{
        text-align: left;
        margin-bottom:50px;
        img{
            height:70px;
        }
    }
    .big{
        font-size:2.4rem;
        line-height:3.2rem;
        font-weight: bold;
        color:#fff;
    }
    .small{
        margin-top:50px;
        font-size:1.6rem;
        line-height:2.4rem;
        font-weight: normal;
        color:#fff;
    }
}
:local(.sidebar){
    grid-column: 2/span 1;
    height: auto;
    .logo{
        display:none;
    }
}

:local(.sidebar .form){
    background: #f5f5f5;
    margin:20px;
    padding:20px;
    border-radius: 10px;    
}


@media(max-width:640px){
    :local(.cnt){
        grid-template-columns: 1fr;
    }   
    :local(.sidebar){
        grid-column: 1/span 1;
        grid-row: 1/span 1;
        .logo{
            display: block;
            text-align: center;
            margin-top:15px;
            margin-bottom:35px;
            img{
                height:50px;
            }
        }
        
    }
    :local(.message){
        grid-column: 1/span 1;
        grid-row: 2/span 1;
        height: auto;
    }


    :local(.message){
        padding:0px 25px 50px 25px;
        .logo{
            display:none;
        }
        .big{
            font-size:1.6rem;
            line-height:2.4rem;
            font-weight: bold;
            color:#fff;
        }
        .small{
            margin-top:30px;
            font-size:1.2rem;
            line-height:1.8rem;
            font-weight: normal;
            color:#fff;
        }
    }
}