:local(.form){
    padding:var(--spacing) 0;
    :local(.title){
        font-size: 1.4rem;
        color:var(--mainColor);
    }

    :local(.header){
        font-size: var(--big);
        margin-bottom:var(--spacing);
        color:var(--mainColor);
    }
    :local(.subtitle){
        font-size: 1rem;
        color:var(--gray3)
    }
    :local(.error){
        margin:20px 0;
    }
    :local(.saveMessage){
        margin-left:var(--spacing);
        display: inline-block;
    }
    :local(.success){
        color:#090;
    }
}
