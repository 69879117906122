:local(.cnt){
    border:1px solid var(--gray6);
    display: grid;
    height:200px;
    width: 200px;
    background:var(--gray7);
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

:local(.btn){
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    background:#000000aa;
    color:#fff;
    text-align: center;
    padding:5px;
    z-index: 1;
}