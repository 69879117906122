:local(.action){
    cursor: pointer;
}
:local(.img){
    width:80px;
    border-radius: 3px;
}
:local(.variants){
    padding-bottom:var(--spacing2x);
}
:local(.variant){
    padding-bottom:var(--spacing2x);
    margin-bottom:var(--spacing2x);
    border-bottom:2px solid var(--gray5);
}

:local(.options){
    margin-top:var(--spacing);
}

@media(max-width:640px){
    :local(.variant) {
        :global(.ant-form-item){
            margin-bottom:0;
        }
        :global(.ant-form-item .ant-form-item-label){
            padding-bottom:0
        }
        
    }
    
    :local(.options){
        :global(.ant-form-item-label){
            display: none;
        }
        :global(.ant-form-item .ant-form-item-label){
            padding-bottom:0
        }
        :global(.ant-form-item .ant-form-item-label > label){
            height:24px;
        }
        
        :global(.ant-form-item){
            margin-bottom:var(--spacing_half);
        }
        :local(.option){
            border-bottom:1px dotted var(--gray5);
            margin-bottom:var(--spacing2x);
        }
    }
    
}