:local(.list){
    display:grid;
    grid-template-columns: repeat(5, 1fr);
    gap: var(--spacing);
    :local(.item){
        img{
            width:100%;
            border:1px solid var(--gray6);
            border-radius: var(--spacing_quarter);
        }
    }
}

@media(max-width: 640px){
    :local(.list){
        display:grid;
        grid-template-columns: repeat(2, 1fr);
    }
}