:local(.cnt) {
  color:#666;
  border-radius:var(--spacing_half);
  padding: var(--spacing) var(--spacing) var(--spacing) var(--spacing);
  display:grid;
  &:local(.withicon){
      grid-template-columns: auto 1fr;
      gap: var(--spacing);
      :local(.icon){
          grid-column: 1/span 1;
      }
      :local(.info){
        grid-column: 2/span 1;
        
      }
      
  }
}

:local(.n) {
  font-size: var(--big8x);
  font-weight: bold;
}
:local(.title) {
  margin-top: 0px;
  font-size: var(--small);
  font-weight: 400;
}

@media(max-width:640px){
  :local(.cnt) {
    padding: var(--spacing_half);
    display:grid;
    &:local(.withicon){
        grid-template-columns: auto 1fr;
        gap: var(--spacing);
    }
  }
  
  :local(.n) {
    font-size: var(--big4x);
  }
  :local(.title) {
    font-size: var(--small);
  }
}