:local(.cnt){
    :local(.form){
        grid-column: 1/span 1;
    }
    :local(.preview){
        grid-column: 2/span 1;
        :local(.inner){
            overflow-y:auto;
            border:1px solid var(--gray5);
            height:600px;
            border-radius: 10px;
        }
    }
    @media(min-width: 640px){
        display: grid;
        gap: 50px;
        grid-template-columns: 1fr 500px;
    }
    @media(max-width: 640px){
        display: block;
    }
}


:local(.actionBar){
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    z-index:10;
    background-color: #f5f5f5;
    box-shadow: 0px -1px 2px rgba(0,0,0,.2);
    border-top:1px solid var(--gray6);
    :local(.inner) {
        max-width: var(--maxWidth);
        margin:0 auto;
        padding:var(--spacing);
    }
    :global(.ant-form-item),
    :global(.ant-form-item-control){
        margin-bottom: 0;
    }
}
