:local(.cnt){
    width:100%;
    padding:0px;
    :local(.header){
        width:100%;
        padding-top: 20%;
        background-size:cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-bottom:5px solid var(--mainColor);
    }
    :local(.info){
        margin:10px 10px;
        display: grid;
        grid-column: 1/span 1;
        grid-template-columns: auto 1fr 200px;
        gap:10px;
    }
    :local(.logo){
        border-radius:5px;
        margin-top:-30px;
        background:#fff;
        width:100px;
        height:100px;
        border:1px solid var(--gray7);
        grid-column: 1/span 1;
        img{
            width:100%;
            height:100%;
        }
    }
    :local(.center){
        grid-column: 2/span 1;
    }
    :local(.icons){
        grid-column: 3/span 1;
        justify-content: end;
        display: grid;
    }

    :local(.name){
        font-weight: bold;
        font-size:var(--big);
        color:var(--gray1)
    }
    :local(.description){
        font-weight: normal;
        font-size:var(--normal);
        color:var(--gray3)
    }
    :local(.icons){
        :local(.icon){
            border-radius: 50px;
            display: inline-block;
            width:32px;
            height:32px;
            zoom:1.3;
            display: grid;
            text-align: center;
            align-items: center;
            justify-items: center;
        }
    }
}


