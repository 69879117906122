:local(.storeSteps){
    ul{
        margin:0;
        padding:0;
        font-weight: 400;
        li{
            list-style:none;
            padding:var(--spacing_half);
        }
        svg{
            margin-right:3px;
            color: var(--mainColor);
            font-size:14px;
        }
    }
    
    :local(.storeStep){
        &:local(.completed){
            color:var(--gray4);
            span {
                text-decoration: line-through;
            }
        }
    }
}

:local(.box){
    padding: var(--spacing);
    margin-bottom: var(--spacing);
    font-size: var(--normal);
    font-weight:400;
    background:var(--mainColorTr);
    border-radius:  var(--spacing_half);
    strong{
        font-weight: 700;
    }
    :local(.header){
        margin:-20px -20px var(--spacing);
        font-size: var(--normal);
        font-weight:400;
        color:#fff;
        background:var(--mainColor);
        padding: var(--spacing_half);
        border-radius: var(--spacing_half) var(--spacing_half) 0 0;
    }
    :local(.info){
        margin:var(--spacing) 0px var(--spacing);
        padding:0;
        font-size: var(--normal);
        color:var(--gray2);
    }
}

:local(.box.warning){
    background:rgb(255, 249, 222);;
    :local(.header){
        background:rgb(248, 156, 65);
        margin:-20px -20px 20px -20px;
        padding: var(--spacing_half);
        svg{
            margin-right:  var(--spacing_half);
            color:#fff
        }
        
    }
    :local(.info){
        padding: var(--spacing_half);
    }
}

:local(.help){
    ul{
        list-style:none;
        padding:0;
        margin:0;
        li{
            padding:5px;
            display:inline-block;
            margin-right:10px;
        }
    }
    svg{
        color:var(--mainColor);
        font-size:32px;
    }
}