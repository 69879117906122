:local(.total){
    border-top:5px solid var(--gray6);
    padding-top:var(--spacing);
    margin-top:var(--spacing);
    margin-bottom:var(--spacing2x);
    font-size: var(--big);
    :local(.label){
        text-align: left;
    }
    :local(.value){
        text-align: right;
    }
}

:local(.actionBar){
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    background-color: #f5f5f5;
    box-shadow: 0px -1px 2px rgba(0,0,0,.2);
    border-top:1px solid var(--gray6);
    :local(.inner) {
        max-width: var(--maxWidth);
        margin:0 auto;
        padding:var(--spacing);
        display:grid;
        grid-template-columns: 1fr 700px;
        grid-template-areas: "save saveAndMove";
        :global(.ant-form-item),
        :global(.ant-form-item-control){
            margin:0;
            padding:0;
        }
        :local(.save){
            grid-area: save;
        }
        :local(.saveAndMove){
            grid-area: saveAndMove;
            text-align: right;
             button{
                margin-left:var(--spacing);
            }
        }
    }
}


@media(max-width:640px){
    :local(.form) {
        padding:var(--spacing_half);
        :global(.ant-form-item){
            margin-bottom: 0;
        }
        :global(.ant-form-item-label){
            padding-bottom:0;
        }
    }
    :local(.save){
        margin-bottom: 100px;
    }
    :local(.actionBar .inner){
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        row-gap:var(--spacing);
        grid-template-areas:
            "saveAndMove";
        :local(.saveAndMove){
            text-align: center;
             button{
                width:100%;
                margin-left:0;
                margin-bottom:var(--spacing);
                font-size: var(--small);
            }
        }
    }
    :local(.rows){
        margin:var(--spacing) 0;
        :global(.ant-form-item){
            margin-bottom: var(--spacing_half);
        }
        
    }
}