:local(.block) {
    border:1px var(--gray5);
    border-style: solid none;
    position:relative;
    margin-top:-1px;
    border-radius: 0;
    :local(.title) {
        padding: var(--spacing_half);
        display: grid;
        grid-template-columns: auto 1fr auto;
        :local(.handler){
            width:40px;
            text-align: center;
            margin-left: -12px;
            color:var(--gray5);
        }
        &:hover{
            :local(.handler){
                color:var(--gray1);
            }
        }
    }
    :local(.body) {
        padding: var(--spacing_half)
    }
}