:local(.title){
    font-size:var(--big);
    color:var(--mainColor);
    margin-bottom: var(--spacing);
}
:local(.cnt){
    svg{
        margin-right:var(--spacing_quarter)
    }
}

@media(max-width:640px){
    :local(.cnt){
        :global(.ant-table){
            font-size: var(--small);
        }
    }
}
