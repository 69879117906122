
:local(.head){

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
}
:local(.header){
    width:100%;
    background-color: #fff;
    border-bottom:2px solid var(--gray6);
    height:50px;
    :local(.inner){
        max-width: var(--maxWidth);
        height: 100%;
        margin: 0 auto;
    }
    :local(.logo){
        height: 100%;
        padding:10px;
        img{
            height: 100%;
        }
    }
}


:local(.menu){

  
    width:100%;
    background-color: var(--gray7);
    box-shadow: 0px 1px 2px rgba(0,0,0,.2);
    height:50px;
    :local(.inner){
        max-width: var(--maxWidth);
        height: 100%;
        margin: 0 auto;
    }
    :local(.options .option){
        display: inline-block;
        padding:0px 10px;
        line-height: 46px;
        border-bottom:5px solid transparent;
        font-size: var(--normal);
        a{
            color:var(--gray2);
            text-decoration: none;
        }
        &.selected{
            font-weight: bold;
            border-bottom:5px solid var(--mainColor);
            a{
                color: var(--mainColor);
            }
        }
    }
}

:local(.main){
    :local(.inner){
        max-width: var(--maxWidth);
        margin: 0 auto;
        min-height: 100vh;
    }
}

:local(.footer){
    border-top:1px solid var(--gray7);
    padding:50px;
    :local(.inner){
        max-width: var(--maxWidth);
        padding:var(--spacing);
        margin: 0 auto;
        :local(.version){
            font-size:var(--small);
        }
    }
}


@media(max-width:640px){
    :local(.menu .options){
        overflow-x: auto;
        /* We don't want vertical scrolling */
        overflow-y: hidden;
        /* Make an auto-hiding scroller for the 3 people using a IE */
        -ms-overflow-style: -ms-autohiding-scrollbar;
        /* For WebKit implementations, provide inertia scrolling */
        -webkit-overflow-scrolling: touch;
        /* We don't want internal inline elements to wrap */
        white-space: nowrap;
        /* Remove the default scrollbar for WebKit implementations */
        &::-webkit-scrollbar {
            display: none;
        }

        :local(.option){
            font-size: var(--normal);
            padding:0px 15px;
        }
    }

}